import React, { useCallback, useMemo, useRef } from 'react'
import AddIcon from '@material-ui/icons/Add'
import DefaultImage from '_assets/images/default.png'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

import useStyles from './styles'
import { AGENCY_FIELDS } from '_/views/agency/constants'
import { useDialog } from '_/hooks/use-dialog'
import { useParams } from '@reach/router'

/**
 * @typedef {Object} Props
 * @property {Function} setValue
 * @property {string} value
 * @property {boolean} [isEditing]
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
const FaviconInput = ({ setValue, value, isEditing = false }) => {
  const { agencyId } = useParams()
  const styles = useStyles()

  const { closeDialog, showDialog } = useDialog()

  const fileInputRef = useRef(null)

  const fileUrl = useMemo(() => {
    if (value instanceof File) {
      return URL.createObjectURL(value)
    }

    return value
  }, [value])

  const handleFileChange = useCallback(
    event => {
      const file = event.target.files?.[0]

      if (file) {
        setValue(AGENCY_FIELDS.FAVICON_URL, file)
      }
    },
    [setValue]
  )

  const clearFile = useCallback(() => {
    showDialog({
      subject: 'Você deseja deletar este item?',
      labelApprove: 'Sim',
      labelCancel: 'Não',
      type: 'info',
      onApprove: () => {
        setValue(AGENCY_FIELDS.FAVICON_URL, null)
        if (fileInputRef.current) {
          fileInputRef.current.value = ''
        }
        closeDialog()
      },
      onCancel: closeDialog,
    })
  }, [setValue, fileInputRef, fileUrl, agencyId])

  const handleAddIconClick = () => {
    if (isEditing && fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const renderContent = () => {
    if (value)
      return (
        <div className={styles.imageContainer}>
          <img src={fileUrl} alt="Favicon preview" className={styles.image} />
          {isEditing && <HighlightOffIcon className={styles.closeIcon} onClick={clearFile} />}
        </div>
      )

    return isEditing ? (
      <div
        className={styles.addFaviconContainer}
        onClick={handleAddIconClick}
        role="button"
        tabIndex={0}
        aria-label="Add Favicon"
        onKeyDown={event => {
          if (event.key === 'Enter' || event.key === ' ') {
            handleAddIconClick()
          }
        }}
      >
        <AddIcon className={styles.addIcon} />
      </div>
    ) : (
      <div className={styles.emptyContainer}>
        <img src={DefaultImage} alt="Favicon preview" className={styles.image} />
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <label className={styles.label} htmlFor="upload-favicon">
        Favicon
      </label>
      <input
        type="file"
        accept=".ico"
        name="upload-favicon"
        className={styles.input}
        onChange={handleFileChange}
        disabled={!isEditing}
        ref={fileInputRef}
      />
      {renderContent()}
    </div>
  )
}

export default FaviconInput
