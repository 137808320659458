import React, { useEffect, useState, useCallback } from 'react'
import { Grid, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import Button from '_components/button'
import { getCsreferaOptions } from '_modules/utils/selectors'
import { getCsrefera } from '_modules/utils/actions'
import { agencySelector } from '_/modules/agency/selectors'
import { AGENCY_FIELDS } from '_/views/agency/constants'

import Accordion from '../index'
import AccordionInfo from '../accordion-info'

import AddCsreferaModal from './add-csrefera-modal'
import useStyles from './styles'
import { getSectorsAgencyByUserId } from '_/modules/agency/actions'

const AddCsrefera = ({
  notSaveState,
  setNotSaveState,
  isEditing,
  setValue,
  errors,
  touched,
  ...otherProps
}) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const agency = useSelector(agencySelector)
  const csreferaOptions = useSelector(getCsreferaOptions)
  const [modalOpen, setModalOpen] = useState(false)
  const [csreferaData, setCsreferaData] = useState([])
  const [isSubmitting, setSubmitting] = useState(false)
  const [csreferaUsers, setCsreferaUsers] = useState()
  const [editingUser, setEditingUser] = useState(null)

  const handleModal = () => {
    setModalOpen(state => !state)
    if (!modalOpen) setEditingUser(null)
  }

  const handleEditUser = user => {
    setEditingUser(user)
    setModalOpen(true)
  }

  const handleSubmit = formValues => {
    const isDuplicated = csreferaData.find(x => x.id === formValues.id)
    if (!formValues?.id || formValues?.id === '' || formValues?.name === '') {
      return
    }

    let updatedData = [...csreferaData]

    if (editingUser) {
      updatedData = csreferaData.map(user => (user.id === editingUser.id ? formValues : user))
      setCsreferaData(updatedData)
    } else if (!isDuplicated) {
      setCsreferaData([...csreferaData, formValues])
    }
    setSubmitting(true)
  }

  const handleAddUserOptions = () => {
    const newCsreferaUsers = []
    csreferaOptions.map(user =>
      newCsreferaUsers.push({ id: user.id, name: user.name, email: user.email })
    )
    setCsreferaUsers([...new Set(newCsreferaUsers)])
    handleModal()
  }

  useEffect(() => {
    handleInitialState(agency?.toJS())
    if (notSaveState) {
      setNotSaveState(false)
    }
  }, [agency, notSaveState])

  const handleInitialState = useCallback(async (agencyData = {}) => {
    const referaUsers = agencyData.responsibleReferaSectors
      ? await Promise.all(
          agencyData?.responsibleReferaSectors?.map(async user => {
            const data = {
              name: user?.userInfo?.name,
              email: user?.userInfo?.email,
              id: user?.user,
              sector: [],
              acceptHelpRequestData: user?.acceptHelpRequests,
            }
            const response = await dispatch(getSectorsAgencyByUserId(user.user))
            if (response?.sector) {
              data.sector.push(response.sector)
            }
            return data
          })
        )
      : []

    setCsreferaData(referaUsers)
  }, [])

  useEffect(() => {
    const users = []
    if (csreferaData.length === 0) {
      setValue(AGENCY_FIELDS.RESPONSIBLE_REFERA, '')
    }
    csreferaData.map(x => users.push({ id: x.id }))
    setValue(AGENCY_FIELDS.RESPONSIBLE_REFERA, csreferaData)
    setSubmitting(false)
  }, [isSubmitting, csreferaData])

  const handleDeleteUser = userObj => {
    const updatedCsreferaData = csreferaData.filter(currentUser => currentUser.id !== userObj.id)
    setCsreferaData(updatedCsreferaData)
    if (updatedCsreferaData.length === 0) {
      setCsreferaData([])
    }
    setSubmitting(true)
  }

  useEffect(() => {
    dispatch(getCsrefera())
  }, [csreferaOptions.length, dispatch])

  return (
    <Accordion
      id="usuarios-refera"
      title="Usuários Refera"
      {...otherProps}
      className={styles.container}
    >
      <Grid className={styles.userList}>
        {csreferaData
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((user, index) => (
            <AccordionInfo
              key={user?.id}
              isFirst={index === 0}
              type="csrefera_users"
              values={user}
              isEditing={isEditing}
              handleDeleteValue={handleDeleteUser}
              userEdit={() => isEditing && handleEditUser(user)}
            />
          ))}
      </Grid>
      {isEditing && (
        <Button
          className={styles.button}
          variant="contained"
          color="primary"
          onClick={handleAddUserOptions}
        >
          + Adicionar registro
        </Button>
      )}
      {errors?.csrefera && touched?.csrefera && (
        <Typography variant="body2" className={styles.error}>
          {errors.csrefera}
        </Typography>
      )}
      {modalOpen && (
        <AddCsreferaModal
          modalOpen={modalOpen}
          handleModal={handleModal}
          handleSubmit={handleSubmit}
          csreferaUsers={csreferaUsers}
          csreferaData={csreferaData}
          initialValues={editingUser}
        />
      )}
    </Accordion>
  )
}

AddCsrefera.propTypes = {
  isCreating: PropTypes.bool,
  setFieldValue: PropTypes.func,
}

AddCsrefera.defaultProps = {
  isCreating: false,
  setFieldValue: () => {},
}

export default AddCsrefera
