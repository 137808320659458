import React, { useCallback, useEffect } from 'react'
import {
  Accordion as MUIAccordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import useBoolean from '_hooks/use-toggle'
import Svg, { ICON } from '_components/svg'
import Button from '_components/button'
import useCepAutoComplete from '_hooks/use-cep-autocomplete'
import { getBankList } from '_modules/utils/actions'
import { bankOptionSelector } from '_modules/utils/selectors'
import { BANK_ACCOUNT_FIELDS } from '_utils/constants'

import InformationWrapper from '../information-wrapper'

import useStyles from './styles'

const ADDRESS_FIELDS = {
  CEP: 'cep',
  ADDRESS: 'address',
  STATE: 'state',
  CITY: 'city',
  NEIGHBORHOOD: 'neighborhood',
}

const Accordion = ({
  fields,
  title,
  id,
  values,
  onChange,
  onBlur,
  touched,
  errors,
  children,
  setFieldValue,
  className,
  classNameContainer,
  isEditing,
  hideEditButton,
}) => {
  const dispatch = useDispatch()
  const [isExpanded, handleExpanded] = useBoolean(true)
  const styles = useStyles({ isExpanded, hasMoreFields: fields.length > 4 })
  const cepData = useCepAutoComplete(values?.cep, isEditing)
  const bankOptions = useSelector(bankOptionSelector)

  const handleStopPropagation = useCallback(event => {
    event.stopPropagation()
  }, [])

  useEffect(() => {
    if (!bankOptions.length && fields.some(field => field.name === BANK_ACCOUNT_FIELDS.BANK)) {
      dispatch(getBankList()).catch(() => {})
    }
  }, [bankOptions.length, dispatch, fields, id])

  const formatCep = cep => {
    if (!cep) return ''
    return cep.replace(/^(\d{5})(\d{3})$/, '$1-$2')
  }

  const handleTextFieldProps = useCallback(
    ({ name, label, mask, ...props }) => {
      const fieldValue = values?.[name] || ''

      const isLink = fieldValue && /^(http|https):\/\/[^ "]+$/.test(fieldValue)

      const renderValue = isLink ? (
        <a href={fieldValue} target="_blank" rel="noopener noreferrer" className={styles.link}>
          {fieldValue}
        </a>
      ) : (
        fieldValue
      )

      return {
        id: name,
        isEditing,
        name,
        label,
        value: isEditing ? fieldValue : renderValue,
        setFieldValue,
        onChange,
        onBlur,
        error: touched[name] && !!errors[name],
        helperText: (touched[name] && errors[name]) || '',
        mask,
        ...props,
      }
    },
    [errors, onBlur, onChange, isEditing, touched, values, setFieldValue]
  )
  useEffect(() => {
    if (cepData?.address?.length) {
      setFieldValue(ADDRESS_FIELDS.ADDRESS, cepData?.address)
      setFieldValue(ADDRESS_FIELDS.STATE, cepData?.state)
      setFieldValue(ADDRESS_FIELDS.CITY, cepData?.city)
      setFieldValue(ADDRESS_FIELDS.NEIGHBORHOOD, cepData?.neighborhood)
      setFieldValue(ADDRESS_FIELDS.CEP, formatCep(cepData.cep))
    }
  }, [cepData, setFieldValue])
  return (
    <MUIAccordion
      elevation={0}
      className={classnames(styles.accordion, classNameContainer)}
      expanded={isExpanded}
      onChange={handleExpanded}
      component="section"
    >
      <AccordionSummary className={styles.summary} aria-controls={id} id={id}>
        <Svg type={ICON.ARROW} className={styles.icon} />
        <Typography variant="h5" component="h1" color="primary">
          {title}
        </Typography>
        {!hideEditButton && (
          <Button type className={styles.openActions} onClick={handleStopPropagation}>
            {isEditing && <Svg type={ICON.EDIT} />}
          </Button>
        )}
      </AccordionSummary>
      <AccordionDetails className={classnames(styles.details, className)}>
        {fields?.length
          ? fields.map(field => (
              <InformationWrapper key={field.name} {...handleTextFieldProps(field)} />
            ))
          : children}
      </AccordionDetails>
    </MUIAccordion>
  )
}

Accordion.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
  // TODO: Define this shape
  values: PropTypes.shape({
    cep: PropTypes.string,
  }),
  touched: PropTypes.shape({}),
  errors: PropTypes.shape({}),
  className: PropTypes.string,
  isEditing: PropTypes.bool,
  hideEditButton: PropTypes.bool,
}

Accordion.defaultProps = {
  fields: [],
  children: null,
  className: '',
  onChange: () => {},
  onBlur: () => {},
  setFieldValue: () => {},
  values: null,
  touched: null,
  errors: null,
  isEditing: false,
  hideEditButton: false,
}

export default React.memo(Accordion)
