import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, spacing }) => ({
  input: {
    display: 'none',
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(8),
    marginBottom: spacing(16),
  },
  label: {
    color: palette.gray[32],
    fontSize: spacing(12),
  },
  emptyContainer: {
    height: spacing(24),
    width: spacing(24),
    backgroundColor: 'lightgray',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  addFaviconContainer: {
    height: 24,
    width: 24,
    cursor: 'pointer',
    backgroundColor: 'lightgray',
    transition: 'background-color 0.3s',

    '&:hover': {
      backgroundColor: palette.gray[32],
    },
  },
  addIcon: {
    color: 'white',
  },
  imageContainer: {
    position: 'relative',
    width: 'fit-content',
  },
  image: {
    height: 24,
    width: 24,
  },
  closeIcon: {
    position: 'absolute',
    top: '-8px',
    right: '-8px',
    fill: '#c90000',
    fontSize: 14,
    transition: 'fill 0.3s',
    cursor: 'pointer',

    '&:hover': {
      opacity: 0.6,
    },
  },
}))
